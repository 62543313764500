import * as React from 'react';
import { loadable } from '@tapestry/shared/lazy-load';
import { TapestrySplashScreen } from '@tapestry/shared/splash-screen';
import { TAPESTRY_APP_CONFIGS } from '@tapestry/shared/constants';

// ! change to retail for now
const { title, color, icon } = TAPESTRY_APP_CONFIGS.retail;

export default loadable(() => import('./lib/market-app-applets-shops-applet'), {
  chunkName: 'market-app-applets-shops-applet',
  fallbackComponent: (
    <TapestrySplashScreen title={title} color={color} icon={icon} />
  ),
});
