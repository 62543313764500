import { TAPESTRY_APP_CONFIGS } from '@tapestry/shared/constants';
import { useProfile } from '@tapestry/shared/hooks';
import { TapestrySplashScreen } from '@tapestry/shared/splash-screen';
import MarketAppAppletsShopsApplet from '@market/applets/shops';
import Head from 'next/head';

export function Index() {
  const [profile] = useProfile();

  if (!profile) {
    const { title, color, icon } = TAPESTRY_APP_CONFIGS.market;
    return <TapestrySplashScreen title={title} color={color} icon={icon} />;
  }

  return (
    <>
      <Head>
        <title>Market Platform</title>
        <meta name="description" content="Market platform by Tapestry.ai" />
      </Head>
      <MarketAppAppletsShopsApplet />
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      hideNavbar: false,
    },
  };
}

export default Index;
