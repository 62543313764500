import * as React from 'react';
import { IIconProp, TapestryLogoIcon } from '@tapestry/shared/icons';
import clx from 'classnames';
import styles from './shared-tapestry-splash-screen.module.css';

interface ITapestrySplashScreenProps {
  title: string;
  color: string;
  icon: React.VFC<IIconProp>;
}

const TapestrySplashScreen: React.FC<ITapestrySplashScreenProps> = ({
  title,
  color,
  icon: Icon,
}) => (
  <div
    className="flex h-screen w-full flex-col items-center justify-center"
    style={{ backgroundColor: color }}
    data-cy="splash-screen"
  >
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="h-32 w-auto">
        <Icon fillColor="#fff" />
      </div>
      <span className="mt-8 block text-xl font-medium text-white">
        Loading {title}
      </span>
      <span className="relative mt-8 inline-flex h-10 w-10 items-center justify-center rounded-full p-2 sm:h-10 sm:w-10">
        <svg
          className="absolute top-0 left-0 h-full w-full rounded-full"
          fill="transparent"
        >
          <circle
            className="absolute top-0 left-0 h-full w-full"
            fill="transparent"
            stroke="rgba(0,0,0,0.2)"
            strokeWidth="6"
            cx="50%"
            cy="50%"
            r="50%"
          />
          <circle
            className={clx(
              styles['svg-circle__spin'],
              'absolute top-0 left-0 h-full w-full'
            )}
            fill="transparent"
            stroke="white"
            strokeWidth="6"
            cx="50%"
            cy="50%"
            r="50%"
            strokeDasharray="129"
            strokeDashoffset="129"
          />
        </svg>
      </span>
    </div>

    <div className="mb-8 text-center text-white">
      <p className="text-xxs mb-2">Powered by</p>
      <TapestryLogoIcon
        width={'40px'}
        height={'40px'}
        className="mx-auto"
        inWhite
      />
      <p className="text-base font-medium">tapestry</p>
    </div>
  </div>
);

export { TapestrySplashScreen };
